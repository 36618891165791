import docCookies from "doc-cookies";
import jQuery from "jquery";

import bundleAddToCart from "../actions/bundle-add-to-cart";
import { showCrossSell } from "../actions/cross-sell";
import injectSummaryContainer from "../dom/injectSummaryContainer";
import runCurrencyUpdate from "../window/runCurrencyUpdate";
import cartPoller from "./cart/cart-poller";
import reloadWindowOnCartChange from "./cart/reload-window-on-cart-change";
import { formatMoney } from "./currency";
import handleUpsellAddToCartClick from "./event-handlers/handle-upsell-add-to-cart-click";
import handleUseDiscountCodeCartApplyClick from "./event-handlers/handle-use-discount-code-cart-apply-click";
import handleUseDiscountCodeInsteadCheckClick from "./event-handlers/handle-use-discount-code-instead-check-click";
import handleVolumeAddToCartClick from "./event-handlers/handle-volume-add-to-cart-click";
import getHost from "./get-host";
import { updateBundlePrice } from "../helpers/bundle-utlity";

export const CART_POLLING_INTERVAL = 1000;

export function addCartDrawerListener() {
  if (!window.saso_ajax_config) return;

  const cartElement = document.querySelector(
    window.saso_ajax_config.selectors.cartDrawer
  );

  if (!cartElement) {
    // eslint-disable-next-line no-console
    console.warn(
      "Ultimate Special Offers - unable to find DOM element for cart"
    );
    return;
  }

  setInterval(function () {
    cartPoller(cartElement);
  }, CART_POLLING_INTERVAL);
}

function addEventListeners() {
  injectSummaryContainer();
  addCartDrawerListener();
  // TODO ch78508 Implement eager loading of badges if they exist in local storage

  /* client only vars, don't pass to server */
  window.sasoc = {
    magnificPopup: jQuery.magnificPopup,
    $cart_offers: jQuery(".saso-cart-offers")
  };

  if (docCookies.getItem("saso_link_code")) {
    window.saso.only_special_link_code = docCookies.getItem("saso_link_code");
  }

  //allow referral links to set a code -- in .liquid, so it works on any page
  if (docCookies.getItem("saso_discount_code")) {
    window.saso.discount_code_cart = docCookies
      .getItem("saso_discount_code")
      .toLowerCase()
      .trim();
  }

  if (docCookies.getItem("saso_generated_discount_code")) {
    //customer went back to cart, delete old code
    //include it in the next call, no need for a separate API
    window.saso.saso_generated_discount_code = docCookies.getItem(
      "saso_generated_discount_code"
    );
    docCookies.removeItem("saso_generated_discount_code");
  }

  //legacy templates
  window.saso_magnificPopup = window.sasoc.magnificPopup;

  /* auto-add cart container -- dont. cust feedback: The special offer used to be a pop-up on my store, now it’s embedded on the cart page and not nearly as effective */
  //force clearing previous discount codes, if customer started checkout and then went back to cart to remove items
  jQuery("<input>")
    .attr({
      type: "hidden",
      name: "discount",
      value: ""
    })
    .appendTo("form[action='/cart']");
  jQuery("<input>")
    .attr({
      type: "hidden",
      name: "clear_discount",
      value: "1"
    })
    .appendTo("form[action='/cart']");

  setTimeout(function () {
    window.sasoc.$first_add_to_cart_el = null;
    /*
      any Add to cart might trigger Upsell
      mega-menu has .widget-product with Add to cart form in them, exclude those -- doesn't have name=, it's ok
    */

    var selectors = [
      "input[name='add']:not(.saso-ignore)",
      "button[name='add']:not(.saso-ignore)",
      "#add-to-cart",
      "#AddToCartText",
      "#AddToCart",
      "#addToCart",
      "button[value='Add to cart']",
      "button[value='Add to Cart']",
      "input[value='Add to cart']",
      "input[value='Add to Cart']",
      "button.addtocart",
      ".product-form--atc-button",
      ".ProductForm__AddToCart",
      ".product-form__payment-container"
    ];
    var found_selectors = 0;

    selectors.forEach(function (selector) {
      found_selectors += jQuery(selector).length;

      //could be multiple on page, e.g. related products. Use only the first
      if (window.sasoc.$first_add_to_cart_el == null && found_selectors) {
        window.sasoc.$first_add_to_cart_el = jQuery(selector).first();
      }
      if (window.saso_config.crosssell_popup_on_add) {
        jQuery(selector).on("click", function () {
          window.saso.action_crosssell_el = { $el: jQuery(this) };
          window.saso.action_crosssell_el.variant_id = null;
          var ids = window.saso.action_crosssell_el.$el
            .parents("form:first")
            .find("[name='id']");
          if (ids.length == 1) {
            window.saso.action_crosssell_el.variant_id = jQuery(ids[0]).val();
          }
          showCrossSell();
        });
      }
    });

    if (
      (window.saso.page_type == "collection" ||
        window.saso.page_type == "product") &&
      found_selectors == 0
    ) {
      //
    }

    //do not add both containers at the same time, some developers put only 1 container and expect it to work
    if (
      window.saso.page_type == "product" &&
      (jQuery(".saso-volumes").length == 0 ||
        jQuery(".saso-bundle").length == 0)
    ) {
      var vol_el_after = null;

      //some themes need special placement
      //https://topmunch.com/collections/shop/products/japan-amanoya-himemaru-rice-cracker
      //if reviews in form, add after Add to cart button, not after form, to be closer to Add ???

      //after add to cart button
      //https://shop.muscletech.com/collections/90-servings-of-vaporx5-next-gen-for-59-99/products/vaporx5-next-gen
      if (window.sasoc.$first_add_to_cart_el != null) {
        vol_el_after = window.sasoc.$first_add_to_cart_el;
        if (vol_el_after.parent().is("div")) {
          vol_el_after = vol_el_after.parent();
        }
      }

      if (vol_el_after && jQuery(".saso-volumes").length == 0) {
        vol_el_after.after('<div class="saso-volumes"></div>');
      }
      if (vol_el_after && jQuery(".saso-bundle").length == 0) {
        vol_el_after.after('<div class="saso-bundle"></div>');
      }
    }

    // Cetain themes like Brooklyn tries to keep the element focus in their
    // drawer cart so that when it is open only the elements in the drawer cart
    // can be focused. That makes the variant selection in USO popup modal
    // unusable.
    // This is to prevent `focusin` event from propogating further up the DOM
    // tree so that it cannot be stolen by themes.
    // We have to use the theme-provided version of jQuery to do so, since
    // different instances of jQuery maintains their internals separately.
    if (window.jQuery) {
      window.jQuery("body").on("focusin", ".saso-cross-sell-popup", event => {
        event.stopPropagation();
      });
    }

    // Disable Magnific Popup focus events once the popup is open.
    // Prestige listens for focus events on the document and then calls focus()
    // on the drawer cart which was causing an infinite loop with this library
    if (
      window.saso_ajax_config &&
      window.saso_ajax_config.themeInfo.name === "Prestige"
    ) {
      jQuery("body").on("focus focusin", ".mfp-wrap", function () {
        jQuery(".mfp-wrap").off("focus focusin");
      });
    }

    // Some themes (e.g. Impulse) close their drawer carts on any click away
    // from the drawer
    jQuery("body").on("click", ".saso-cross-sell-popup", function (e) {
      e.stopPropagation();
    });

    jQuery("body").on(
      "click",
      ".saso-volume-discount-tiers .saso-add-to-cart",
      handleVolumeAddToCartClick
    );
    //legacy templates, we can't update but need to keep working: .add-to-cart
    jQuery("body").on(
      "click",
      ".saso-volume-discount-tiers .add-to-cart",
      handleVolumeAddToCartClick
    );

    jQuery("body").on(
      "click",
      ".saso-cross-sell-popup .saso-add-to-cart:not(.saso-bundle-add-to-cart)",
      handleUpsellAddToCartClick
    );
    //legacy templates, we can't update but need to keep working: .add-to-cart
    jQuery("body").on(
      "click",
      ".saso-cross-sell-popup .add-to-cart",
      handleUpsellAddToCartClick
    );

    jQuery("body").on("click", ".saso-bundle-add-to-cart", bundleAddToCart);

    jQuery("body").on(
      "click",
      "#saso-use-discount-code-cart-apply",
      handleUseDiscountCodeCartApplyClick
    );
    jQuery("body").on(
      "click",
      ".saso-use-discount-code-cart-apply",
      handleUseDiscountCodeCartApplyClick
    );
    jQuery("body").on(
      "change",
      "#saso-use-discount-code-instead-check",
      handleUseDiscountCodeInsteadCheckClick
    );

    jQuery("body").on("click", ".saso-crosssell-nav", function () {
      //for reporting, log in our db that a product was chosen
      var saso_product_id = jQuery(this).data("product-id");
      var offerType = window.saso.action_crosssell_popup_action.type;
      if (offerType === "Upsell") {
        var offerId = window.saso.action_crosssell_popup_action.id;
        jQuery.ajax({
          type: "POST",
          dataType: "json",
          url: "https://" + getHost() + "/api/v1/cross-sell-add",
          data: {
            shop_slug: window.saso.shop_slug,
            product_id: saso_product_id,
            cart_token: window.saso.cart.token,
            offer_id: offerId
          },
          success: function () {}
        });
      }
    });

    // set prevId before user clicks on variant dropdown
    jQuery("body").on("focusin", ".saso-variants", function () {
      jQuery(this).data("prevId", jQuery(this).val());
    });
    //upsell popup: change image to selected variant
    jQuery("body").on("change", ".saso-variants", function (ev) {
      var prevId = jQuery(this).data("prevId");
      jQuery(this).data("prevId", jQuery(this).val()); // Special case when dropdown is already focused.
      var variantId = jQuery(this).val();
      var img = jQuery(this).find(":selected").data("img");
      if (typeof img == "string" && img.length) {
        jQuery(ev.target)
          .closest(".saso-product-container")
          .find("img")
          .attr("src", img);
      }

      var qty = jQuery(this).find(":selected").data("qty");
      var price = formatMoney(
        jQuery(this).find(":selected").data("price"),
        window.saso.money_format,
        window.saso_config.tax_percent
      );
      var compare_at_price = formatMoney(
        jQuery(this).find(":selected").data("compare-at-price"),
        window.saso.money_format,
        window.saso_config.tax_percent
      );
      var newProduct = {
        [variantId]: {
          price: jQuery(this).find(":selected").data("price"),
          qty: qty
        }
      };
      if (window.saso_bundle && window.saso_bundle.bundle) {
        updateBundlePrice(prevId, newProduct);
      }
      jQuery(ev.target)
        .closest(".saso-product-container")
        .find(".saso-price")
        .html(price);
      jQuery(ev.target)
        .closest(".saso-product-container")
        .find(".saso-was-price")
        .html(compare_at_price);
      runCurrencyUpdate();
    });

    if (window.saso.page_type == "cart") {
      jQuery(document).ajaxComplete(reloadWindowOnCartChange);

      //auto-reload cart on quantities change, except when asked to ignore
      //doesn't work...
      jQuery("body").on(
        "change",
        'input[name="updates[]"]:not(.saso-ignore)',
        function () {
          jQuery('[name="update"]').click();
        }
      );

      //venture
      setTimeout(function () {
        if (typeof window.QtySelector == "function") {
          window.QtySelector.prototype.updateCartItemCallback = function () {
            location.reload();
          };
        }
      }, 2000);
    }

    //prevent direct checkouts from popups, quick view... in .liquid

    jQuery("body").on(
      "click",
      ".saso-cross-sell-popup .saso-close",
      function (ev) {
        ev.preventDefault(); //cart is submitted when inside <form>
        if (window.saso.action_crosssell_popup_action) {
          window.saso.action_crosssell_popup_action.manually_closed = true;
        }

        window.sasoc.magnificPopup.instance.close();
      }
    );
  }, 1); //no need to wait, we're included at the end. If we wait more, offers will run and won't find elements in page
}

export default addEventListeners;
