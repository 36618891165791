import jQuery from "jquery";
import { formatMoney } from "../lib/currency";

function updateBundleUI(bundleConfig) {
  jQuery(".saso-before-price").html(bundleConfig.totalBundlePrice);
  jQuery(".saso-after-price").html(bundleConfig.discountedBundlePrice);
}

function calculateBundlePrices(bundleProducts, discountType, discountAmount) {
  const totalBundlePrice = Object.values(bundleProducts).reduce(
    (acc, product) => acc + product.price * product.qty,
    0
  );

  let discountedBundlePrice = totalBundlePrice;
  if (discountType === "subtract") {
    discountedBundlePrice -= discountAmount * 100;
  } else if (discountType === "flat") {
    discountedBundlePrice = discountAmount * 100;
  } else {
    const discount = (discountAmount * totalBundlePrice) / 100;
    discountedBundlePrice -= discount;
  }

  return { totalBundlePrice, discountedBundlePrice };
}

function formatBundlePrices(
  totalBundlePrice,
  discountedBundlePrice,
  moneyFormat,
  taxPercent
) {
  return {
    totalBundlePrice: formatMoney(totalBundlePrice, moneyFormat, taxPercent),
    discountedBundlePrice: formatMoney(
      discountedBundlePrice,
      moneyFormat,
      taxPercent
    )
  };
}

function updateBundleProduct(bundleProducts, oldVariantId, newProduct) {
  if (oldVariantId) {
    delete bundleProducts[oldVariantId];
  }
  const variantId = Object.keys(newProduct)[0];
  bundleProducts[variantId] = newProduct[variantId];
}

export function updateBundlePrice(oldVariantId, newProduct) {
  const bundleConfig = window.saso_bundle.bundle;
  if (oldVariantId) {
    updateBundleProduct(bundleConfig.bundleProducts, oldVariantId, newProduct);
  }

  const { totalBundlePrice, discountedBundlePrice } = calculateBundlePrices(
    bundleConfig.bundleProducts,
    bundleConfig.discountType,
    bundleConfig.discountAmount
  );

  const formattedPrices = formatBundlePrices(
    totalBundlePrice,
    discountedBundlePrice,
    window.saso.money_format,
    window.saso_config.tax_percent
  );

  bundleConfig.totalBundlePrice = formattedPrices.totalBundlePrice;
  bundleConfig.discountedBundlePrice = formattedPrices.discountedBundlePrice;

  updateBundleUI(bundleConfig);
}
