// @flow

import type { CSSCustomizations } from "types/flow-types/customizations";

const ruleSelectors = {
  "text-heading": [
    ".saso-title",
    ".saso-bundle-popup .saso-message",
    ".saso-volumes h4"
  ],
  "text-body": [
    ".saso-volumes",
    ".saso-bundle",
    ".saso-cross-sell-popup",
    ".saso-summary-line-buyxgety",
    ".saso-cart-item-upsell-notes",
    ".saso-cart-item-discount-notes",
    ".saso-variants",
    ".saso-table td"
  ],
  "text-sale": [
    ".saso-price",
    ".saso-bundle-popup .saso-equal-container",
    ".saso-bundle-popup .saso-plus-container"
  ],
  "button-primary": [
    ".saso-cross-sell-popup .saso-add-to-cart",
    ".saso-cross-sell-popup button.saso-click-here"
  ],
  "table-even": [
    ".saso-volume-discount-tiers .saso-table tbody tr:nth-child(even)"
  ],
  "table-odd": [
    ".saso-volume-discount-tiers .saso-table tbody tr:nth-child(odd)"
  ],
  popup: [
    ".saso-cross-sell-popup:not(.saso-bundle-popup)",

    // override Brooklyn's generic <table> styles
    ".saso-cross-sell-popup:not(.saso-bundle-popup) table"
  ],
  bundle: [".saso-cross-sell-popup.saso-bundle-popup"],
  bundle_new: [
    ".saso-cross-sell-popup.saso-bundle-popup.new-saso",
    ".saso-cross-sell-popup.new-saso .saso-default-message",
    ".saso-cross-sell-popup.new-saso .saso-default-message .saso-bundle-discount-icon"
  ]
};

// hides weird artifacts of the legacy hardcoded css
// appended to the end of customization css
// Unchangable global overrides
const hacks = [
  // removes 1px border above upsell modal image ¯\_(ツ)_/¯
  ".saso-cross-sell-popup table > tbody > tr { border-top: none; }"
];

function getPropertyKeys(css: CSSCustomizations, ruleLookup: string) {
  return ruleLookup.split("-").reduce(function (acc, key) {
    return acc && acc[key] ? acc[key] : undefined;
  }, css);
}

function generateCSS(cssCustomizations: CSSCustomizations): string {
  return Object.keys(ruleSelectors)
    .reduce(function (acc, ruleLookup) {
      const propertyKeys = getPropertyKeys(cssCustomizations, ruleLookup);
      const selector = ruleSelectors[ruleLookup].join(",");

      if (!propertyKeys) return acc;

      const properties = Object.keys(propertyKeys).map(function (property) {
        const value = propertyKeys[property];

        return value && property + ": " + value + ";";
      });

      if (!properties.filter(Boolean).length) return acc;

      return (acc += selector + "{" + properties.join("") + "}");
    }, "")
    .concat(hacks.join("\n"));
}

module.exports = {
  generateCSS: generateCSS,
  hacks: hacks,
  ruleSelectors: ruleSelectors,
  getPropertyKeys: getPropertyKeys
};
