import jQuery from "jquery";

import addItems from "../lib/cart/add-items";
import reloadAndOpenCart from "../lib/reload-and-open-cart";

function bundleAddToCart(ev) {
  jQuery(this).prop("disabled", true);
  ev.preventDefault(); //cart is submitted when inside <form>

  //variants & quantities

  var items_to_add = [];
  var items_to_add_count = 0;
  jQuery(".saso-product-container").each(function () {
    var item = {
      id: jQuery(this).data("variant-id"),
      quantity: jQuery(this).data("quantity")
    };
    var product_id = jQuery(this).data("product-id");
    items_to_add_count++;

    //variant chosen?
    var $variants = jQuery(
      ".saso-product-container[data-product-id='" + product_id + "']"
    ).find("select.saso-variants");
    if ($variants.length == 1) {
      var variant_id = parseInt($variants.val());
      if (variant_id == 0) {
        alert(window.saso_config.upsell_variant_choose_message);
        return;
      }
      item.id = variant_id;
    }

    items_to_add.push(item);
  });

  //some variants might not have been selected. Add only if all items are ready
  if (items_to_add.length != items_to_add_count) {
    jQuery(this).prop("disabled", false);
    return;
  }
  addItems(items_to_add, function () {
    //sometimes cart doesn't actually contain the product??
    setTimeout(function () {
      if (window.saso_ajax_config) {
        reloadAndOpenCart();
      } else {
        window.location.href = "/cart";
      }
    }, 100);
  });
}

export default bundleAddToCart;
