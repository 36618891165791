import jQuery from "jquery";

import { formatMoney } from "../lib/currency";
import { trimLength, escapeHTML } from "../lib/string";
import { queryShopifyProducts } from "../lib/shopifyAjax";

import runCurrencyUpdate from "../window/runCurrencyUpdate";
import { getMediumImageUrl } from "./cross-sell";
import filterNonApplicableVariants from "./filter-non-applicable-variants";
import compileMarkup from "./compile-markup";
import { updateBundlePrice } from "../helpers/bundle-utlity";

export async function prepareBundle(action) {
  // TODO: there are potentially cases where there could be different bundles with the
  // same product, just different variants for each bundle. In that case,
  // we're fetching the same data multiple times
  const shopifyProducts = await queryShopifyProducts(action.products);

  const products = shopifyProducts
    .map(product => prepareProductForWidget(action, product))
    .filter(p => p);

  // bundles will only work if all products are available.
  // this denotes that one of the bundled products failed a fetch from Shopify:
  // we think it's available when it is in fact not. OR all of the targeted
  // are out of stock variants
  if (products.length < action.products.length) {
    return false;
  }

  //if all products don't have any variants, fully remove variants container to save space
  var no_variants = true;

  for (var i = 0; i < products.length; i++) {
    if (!products[i].hasDefaultVariant) {
      no_variants = false;
    }
    products[i].quantity = 1;
    products[i].quantityx = "";
    var currentp = action.products.filter(function (p) {
      return p.id == products[i].id;
    });
    if (currentp.length == 1 && currentp[0].quantity > 1) {
      products[i].quantityx = "(" + currentp[0].quantity + "x)";
      products[i].quantity = currentp[0].quantity;
    }
  }

  let bundleProducts = {};
  for (let i = 0; i < products.length; i++) {
    let variantId = products[i].variants[0].id;
    let price = products[i].variants[0].price_n;
    let qty = products[i].quantity;
    bundleProducts[variantId.toString()] = {
      price,
      qty
    };
  }
  window.saso_bundle = {};
  window.saso_bundle.bundle = {};
  window.saso_bundle.bundle.bundleProducts = bundleProducts;
  window.saso_bundle.bundle.discountType = action.price.type;
  window.saso_bundle.bundle.discountAmount = action.price.amount;
  if (no_variants) {
    for (let i = 0; i < products.length; i++) {
      //products[i].variants_style = "display: none;"
      products[i].variants_style = "height: 0px;";
    }
  }

  if (window.saso_config.translate_percent_off) {
    action.price.title = action.price.title.replace(
      "% Off",
      window.saso_config.translate_percent_off
    );
  }

  //Add translating for the Discount amount
  if (window.saso_config.translate_amount_off) {
    action.price.title = action.price.title.replace(
      " off",
      window.saso_config.translate_amount_off
    );
  }

  const selector = "#saso-bundle-popup";
  const templateData = {
    notifications_message: action.message,
    products: products,
    bundle_price_type: action.price.type,
    bundle_price_title: action.price.title,
    message_after: action.message_after
  };

  return compileMarkup(selector, templateData);
}

export function showBundle(html) {
  jQuery(".saso-bundle").html(html);
  runCurrencyUpdate();
  updateBundlePrice();
}

function prepareProductForWidget(action, product) {
  if (product.variants.length === 1) {
    product = { ...product, hasDefaultVariant: true };
  }
  product = filterNonApplicableVariants(product, action);

  for (var i = 0; i < product.variants.length; i++) {
    //transform prices from $ strings to cents float
    if (typeof product.variants[i].compare_at_price == "string") {
      product.variants[i].compare_at_price =
        parseFloat(product.variants[i].compare_at_price) * 100;
    }
    if (typeof product.variants[i].price == "string") {
      product.variants[i].price = parseFloat(product.variants[i].price) * 100;
    }

    //from here on, transform prices to formatted strings
    product.variants[i].compare_at_price_n =
      product.variants[i].compare_at_price;
    product.variants[i].price_n = product.variants[i].price;
    if (
      product.variants[i].compare_at_price &&
      product.variants[i].compare_at_price > product.variants[i].price
    ) {
      product.variants[i].compare_at_price = formatMoney(
        product.variants[i].compare_at_price,
        window.saso.money_format,
        window.saso_config.tax_percent
      );
    } else {
      product.variants[i].compare_at_price = "";
    }
    product.variants[i].price = formatMoney(
      product.variants[i].price,
      window.saso.money_format,
      window.saso_config.tax_percent
    );
  }

  const productImage =
    product.featured_image || (product.images && product.images[0]);
  product.image = {};

  if (productImage) {
    product.image.src = getMediumImageUrl(productImage);
  }

  product.variants_style = "";
  product.variants_select = "";
  if (product.hasDefaultVariant) {
    product.variants_style = "visibility: hidden;";
    //legacy templates
    product.style_options = "visibility: hidden;";
  } else {
    var variants_html = "";
    product.variants.map(function (v) {
      if (!v.available) return;

      var img = "";
      if (v.featured_image) {
        img = v.featured_image.src;
      } else {
        //null, show default
        img = productImage;
      }

      img = getMediumImageUrl(img);
      const currentP = action.products.filter(
        item => product.id.toString() === item.id.toString()
      );
      let qty = currentP.length > 0 ? currentP[0]?.quantity : 1;
      variants_html +=
        "<option value='" +
        v.id +
        "' data-img='" +
        img +
        "' data-price='" +
        v.price_n +
        "' data-compare-at-price='" +
        v.compare_at_price_n +
        "' data-qty='" +
        qty +
        "'>";
      variants_html += escapeHTML(v.title) + "</option>";
    });

    // all variants out of stock
    if (variants_html === "") {
      return null;
    }

    product.variants_select = "<select class='saso-variants'>";
    if (
      typeof window.saso_config.upsell_variant_choose_option == "string" &&
      window.saso_config.upsell_variant_choose_option.length
    ) {
      product.variants_select +=
        "<option value='0'>" +
        escapeHTML(window.saso_config.upsell_variant_choose_option) +
        "</option>";
    }
    product.variants_select += variants_html + "</select>";
  }

  product.title = trimLength(
    product.title,
    window.saso_config.product_title_max_length
  );

  return product;
}
